<template>
    <div id="secure">
        <h1>Auftragsübersicht</h1>
        Hier siehst Du alle Deine gespeicherten PAWs<br>&nbsp;<br>&nbsp;<br>&nbsp;
        <div v-if="$store.state.paws && $store.state.paws.length > 0" id="tablecontainer">
            <!-- small screens -->
            <table v-show="$vssWidth <= 960">
                <tr>
                    <th>Titel</th>
                    <th>Status</th>
                    <th>Aktionen</th>
                </tr>
                <tr v-for="paw in paws" v-bind:key="paw.pawId">
                    <td>{{ paw.descriptionSmallScreen }}</td>
                    <td>{{ toDescription(paw.status) }}</td>
                    <td>
                        <md-button class="md-raised" :to="'/user/paw/detail/'+paw.pawId">
                            Details
                        </md-button>
                        <md-button v-if="offerable(paw.status)" class="md-raised"
                                   :to="'/user/paw/offer/'+paw.pawId">
                            Beauftragen
                        </md-button>
                        <md-button v-if="picsAddable(paw.status)" class="md-raised"
                                   :to="'/user/paw/addimages/'+paw.pawId">
                            Bebildern
                        </md-button>
                    </td>
                </tr>
            </table>
            <!-- large Screens -->
            <table v-show="$vssWidth > 960">
                <tr>
                    <th>Bezeichnung</th>
                    <th>Status</th>
                    <th>Aktionen</th>
                </tr>
                <tr v-for="paw in paws" v-bind:key="paw.pawId">
                    <td>{{ paw.description }}</td>
                    <td>{{ toDescription(paw.status) }}</td>
                    <td>
                        <md-button class="md-raised" :to="'/user/paw/detail/'+paw.pawId">
                            Details
                        </md-button>
                        <md-button v-if="offerable(paw.status)" class="md-raised"
                                   :to="'/user/paw/offer/'+paw.pawId">
                            Beauftragen
                        </md-button>
                        <md-button v-if="picsAddable(paw.status)" class="md-raised"
                                   :to="'/user/paw/addimages/'+paw.pawId">
                            Bebildern
                        </md-button>
                    </td>
                </tr>
            </table>
        </div>
        <div v-else>
            - keine PAWs vorhanden -
        </div>

        <br>&nbsp;

        <md-button class="md-raised md-primary" to="/user/paw/new/">Neuen PAW erstellen</md-button>
    </div>
</template>

<script>
import {offerable, picsAddable, toDescription} from "../helpers/PAWstates";
import VueScreenSize from 'vue-screen-size';

export default {
    name: 'User_Dashboard',
    mixins: [VueScreenSize.VueScreenSizeMixin],
    props: {
    },
    data: () => ({
    }),
    components: {},
    computed: {
        paws: function () {
            for (let p of this.$store.state.paws){
                p.descriptionSmallScreen = p.description.replace(/_/g, " ")
            }
            return this.$store.state.paws;
        }
    },
    mounted() {
    },
    methods: {
        offerable,
        picsAddable,
        toDescription,
    }
}
</script>

<style scoped>
#secure {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}

div#tablecontainer {
    text-align: center;
    max-width: 90%;
    margin: auto;
}

table {
    display: inline-block;
    border-collapse: collapse;
}

table#table > tr > th {
    font-size: large;
    padding: 10px;
}

table#table > tr > td {
    padding: 10px;
}

tr:nth-child(odd) {
    background: #EEE;
}

tr:nth-child(even) {
    background: #FFF;
}
</style>
